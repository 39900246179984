import React from 'react'

import { authService } from '../auth';
import axios from "axios";
import FooterDiv from "../FooterDiv";
import CreatableSelect from 'react-select/creatable';
import httpService from '../AxiosInterceptors';
import {Link, Redirect} from "react-router-dom";
import Select from 'react-select'
import * as _ from "lodash";
import { userAgentPostData } from '../commons/CommonFunctions';
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import { setWpondYearData } from '../reducer/wpond/WpondReducer';

httpService.setupInterceptors();

const ErorrMsgPopUp = withReactContent(alertPopUp);

const userUpdatePersona = () => {
    return ({
        title: `Updating your profile helps members of WeNaturalists discover you. You can get better recommendations as per your experience, skills and interests. You can even update it later from the edit option on your profile page.`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Update Now',
        cancelButtonText: 'Later',
        allowOutsideClick: false,
    })
};

class UserAddress extends React.Component {

    constructor(props) { 
        super(props);
        let validation = {
            country: {},
            state: {},
            city: {}
        }
        this.state = {
            isLoading: true,
            validation,
            countries: [],
            states: [],
            cities: [],
            country: '',
            state: '',
            city: '',
            phone: '',
            errors: {},
            userData: authService.getUserDetailsFromLocalStorage(),
            data: {},
            selectedOption: null,
            selectedOption1: null,
            selectedOption2: null
        };

    }

    fetchCountries = () => {
        //this.state.countries.push("Select Country *");
        axios({
            method: "get",
            url: process.env.REACT_APP_userServiceURL + "/backend/region/countries",
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                this.setState({ countries: this.state.countries.concat(response.data.body), state: 'Select State *', city: 'Select City *'  });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleKeyDown = (e) => {
        if(e.keyCode === 32) {
            e.preventDefault();
            this.ref.onInputChange(this.ref.state.inputValue + " ", "set-value");
        }
    };

    componentDidMount() {
        // let DefaultCountry = {value:this.props.location.state.signUpResponse.country, label:this.props.location.state.signUpResponse.country}
        // this.setState({selectedOption:DefaultCountry})
        if(!this.props?.location?.state?.signUpResponse) {
            this.props.history.push({
                pathname: '/'
            })
        }
        this.props.setWpondYearData(true);
        this.fetchCountries();
        this.setState({ data: this.props?.location?.state?.signUpResponse });
        window.$('#react-select-4-input').on('keypress', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
               event.preventDefault();
               return false;
            }
        });
    }

    getStateList = () => {
        const { selectedOption1 } = this.state;
        return (
            <div className="form-group">
                <CreatableSelect
                    value={selectedOption1}
                    options={this.getStatesOptions()}
                    onChange={this.getState}
                    styles={
                        this.state.validation &&
                        this.state.validation.state &&
                        this.state.validation.state.style
                        ? {
                            control: 
                                styles => ({ 
                                    ...styles, 
                                    borderColor: '#b34c4c',
                                    ":hover": {
                                        ...styles[":hover"],
                                        borderColor: '#b34c4c',
                                        }
                                }),
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                        : {                            
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                    }
                    onInputChange={this.handleCreate1}
                    placeholder={'Select State *'}
                    name={'state'}
                    classNamePrefix='react-select-other'
                />
                <span className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors.state}</span>
                <span
                    className="login-feedback-error mt-1">{this.state.validation.state && this.state.validation.state.message}</span>

            </div>
        );
    };
    getCountryList = () => {
        const { selectedOption } = this.state;
        return (
            <div className="form-group">
                <Select
                    value={selectedOption}
                    options={this.getCountriesOptions()}
                    onChange={this.getCountry}
                    styles={
                        this.state.validation &&
                        this.state.validation.country &&
                        this.state.validation.country.style
                        ? {
                            control: 
                                styles => ({ 
                                    ...styles, 
                                    borderColor: '#b34c4c',
                                    ":hover": {
                                        ...styles[":hover"],
                                        borderColor: '#b34c4c',
                                        }
                                }),
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                        : {                            
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                    }
                    placeholder={'Select Country *'}
                    onInputChange={this.handleCreate}
                    name={'country'}
                    classNamePrefix='react-select-other'
                />
                <span className="login-feedback-error mt-1"
                    style={{ display: "block" }}>{this.state.errors.country}</span>
                <span
                    className="login-feedback-error mt-1">{this.state.validation.country && this.state.validation.country.message}</span>
            </div>
        );
    };

    getCitiesList = () => {
        const { selectedOption2 } = this.state;
        return (
            <div className="form-group">
                <CreatableSelect
                    value={selectedOption2}
                    options={this.getCitiesOptions()}
                    onChange={this.getCity}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                    ref={ref => {
                        this.ref = ref;
                    }}
                    styles={
                        this.state.validation &&
                        this.state.validation.city &&
                        this.state.validation.city.style
                        ? {
                            control: 
                                styles => ({ 
                                    ...styles, 
                                    borderColor: '#b34c4c',
                                    ":hover": {
                                        ...styles[":hover"],
                                        borderColor: '#b34c4c',
                                        }
                                }),
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                        : {                            
                            option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                            ) => {
                            const color = "#cfe7c7";
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? null
                                    : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                    ? "#cfe7c7"
                                    : null,
                                color: isDisabled
                                    ? "#ccc"
                                    : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",
        
                                ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                            };
                            },
                        }
                    }
                    onInputChange={this.handleCreate2}
                    placeholder={'Select City *'}
                    name={'city'}
                    classNamePrefix='react-select-other'
                />
                <span className="login-feedback-error mt-1" style={{ display: "block" }}>{this.state.errors.city}</span>
                <span
                    className="login-feedback-error mt-1">{this.state.validation.city && this.state.validation.city.message}</span>
            </div>
        );
    };

    getCountriesOptions = () => {
        let countriesList = this.state.countries.map((country) => {
            return { value: country, label: country, name: 'country' }
        });
        return countriesList; 
    };

    getCitiesOptions = () => {
        let cities = this.state.cities.map((city) => {
            return { value: city, label: city, name: 'city' }
        });
        return cities;
    };

    getStatesOptions = () => {
        let states = this.state.states.map((state) => {
            return { value: state, label: state, name: 'state' }
        });
        return states;
    };
    checkValidation = () => {
        this.state.validation.country = {};
        this.state.validation.state = {};
        this.state.validation.city = {};
        let errors = {};
        let formIsValid = true;
        let validationRequired = false;
        if (this.state.country === '' || this.state.country === 'Select Country *') {
            errors["country"] = "Please select a country";
            this.state.validation.country = {
                error: true,
                style: {
                    container: (provided, state) => ({
                        ...provided,
                        border: '1px solid #b34c4c',
                        color:"#00394d",
                        textAlign:'left',
                        fontFamily:'"Montserrat", sans-serif',
                        fontSize:"14px",
                        borderRadius:'5px'
                    }),
                }
            }
            formIsValid = false;
            validationRequired = true;

        }
        if (this.state.state === '' || this.state.state === 'Select State *') {
            validationRequired = true;
            errors["state"] = "Please select a state";
            this.state.validation.state = {
                error: true,
                style: {
                    container: (provided, state) => ({
                        ...provided,
                        border: '1px solid #b34c4c',
                        color:"#00394d",
                        textAlign:'left',
                        fontFamily:'"Montserrat", sans-serif',
                        fontSize:"14px",
                        borderRadius:'5px'
                    }),
                }
            }
            formIsValid = false;
        }
        if (this.state.city === '' || this.state.city === 'Select City *') {
            validationRequired = true;
            errors["city"] = "Please select a city";
            this.state.validation.city = {
                error: true,
                style: {
                    container: (provided, state) => ({
                        ...provided,
                        border: '1px solid #b34c4c',
                        color:"#00394d",
                        textAlign:'left',
                        fontFamily:'"Montserrat", sans-serif',
                        fontSize:"14px",
                        borderRadius:'5px'
                    }),
                }
            }
            formIsValid = false;
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    };
    

    validate = (fieldName, newValue) => {
        let isValid = true;
        switch (fieldName) {
            case "country":
                const fn = /^[a-zA-Z\s]+$/;
                if (newValue.value === '' || fn.test(newValue.value)) {
                    this.setState({country: newValue.value});
                }

                this.state.validation.country = {};
                if (
                    _.isUndefined(newValue.value) ||
                    _.isEmpty((newValue.value || "").toString()) ||
                    _.isNull(newValue.value) ||
                    newValue.value.trim().replace(" ", "").length > 25 ||
                    newValue.value.trim().replace(" ", "").length < 2
                ) { 
                    this.state.validation.country = {
                        error: true,
                        message: 'Please select a country',
                        style: {
                            container: (provided, state) => ({
                                ...provided,
                                border: '1px solid #b34c4c',
                                color:"#00394d",
                                textAlign:'left',
                                fontFamily:'"Montserrat", sans-serif',
                                fontSize:"14px",
                                borderRadius:'5px'
                            }),
                        }
                    }

                }
                break;

            default:
                isValid = true;
                break;
        }
        return isValid;
    };

    getCountry = (newValue, actionMeta) => {
        const fn = /^[a-zA-Z\s]+$/;
                this.state.validation.country = {};
                if (
                    _.isUndefined(newValue.value) ||
                    _.isEmpty((newValue.value || "").toString()) ||
                    _.isNull(newValue.value)
                ) {
                    this.state.validation.country = {
                        error: true,
                        message: 'Please select a country',
                        style: {
                            container: (provided, state) => ({
                                ...provided,
                                border: '1px solid #b34c4c',
                                color:"#00394d",
                                textAlign:'left',
                                fontFamily:'"Montserrat", sans-serif',
                                fontSize:"14px",
                                borderRadius:'5px'
                            }),
                        }
                    }

                }
        let value = newValue.value;
        //this.state.validation.country = {};
        this.setState({ country: value });
        this.setState({ states: [] });
        this.setState({ cities: [] });
        this.setState({ city: '' });
        this.setState({ state: '' });
        this.setState({ selectedOption: newValue });
        this.setState({ selectedOption1: null })
        this.setState({ selectedOption2: null })
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/backend/region/states/" +
                value,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                this.setState({ states: response.data.body }, () => {
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }


    getState = (newValue1, actionMeta1) => {
        const fn = /^[a-zA-Z\s]+$/;
                this.state.validation.state = {};
                if (
                    _.isUndefined(newValue1.value) ||
                    _.isEmpty((newValue1.value || "").toString()) ||
                    _.isNull(newValue1.value)
                ) {
                    this.state.validation.state = {
                        error: true,
                        message: 'Please select a state',
                        style: {
                            container: (provided, state) => ({
                                ...provided,
                                border: '1px solid #b34c4c',
                                color:"#00394d",
                                textAlign:'left',
                                fontFamily:'"Montserrat", sans-serif',
                                fontSize:"14px",
                                borderRadius:'5px'
                            }),
                        }
                    }

                }
        let name = newValue1.name
        let value = newValue1.value;
        //this.state.validation.state = {};
        this.setState({ cities: [] });
        this.setState({ city: '' });
        this.setState({ state: value });
        this.setState({ selectedOption1: newValue1 })
        this.setState({ selectedOption2: null })
        axios({
            method: "get",
            url:
                process.env.REACT_APP_userServiceURL +
                "/backend/region/cities/" +
                value,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                this.setState({ cities: response.data.body }, () => {

                });
            })
            .catch((err) => {
                console.log(err);
            });

    }

    getCity = (newValue2, actionMeta2) => {
        const fn = /^[a-zA-Z\s]+$/;
                this.state.validation.city = {};
                if (
                    _.isUndefined(newValue2.value) ||
                    _.isEmpty((newValue2.value || "").toString()) ||
                    _.isNull(newValue2.value)
                ) {
                    this.state.validation.city = {
                        error: true,
                        message: 'Please select a city',
                        style: {
                            container: (provided, state) => ({
                                ...provided,
                                border: '1px solid #b34c4c',
                                color:"#00394d",
                                textAlign:'left',
                                fontFamily:'"Montserrat", sans-serif',
                                fontSize:"14px",
                                borderRadius:'5px'
                            }),
                        }
                    }

                }
        let name = newValue2.name
        let value = newValue2.value;
        this.setState({ selectedOption2: newValue2 })
        this.setState({ city: value });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.checkValidation()) {

            let postBody = {
                "userId": this.state.userData.userId,
                "country": this.state.country,
                "state": this.state.state,
                "district": this.state.city,
                "userAgent": userAgentPostData()
            };
            axios({
                method: 'post',
                url: process.env.REACT_APP_userServiceURL + '/user/update/address',
                headers: { 'Content-Type': 'application/json' },
                data: postBody,
                withCredentials: true
            }).then((response) => {
                if (response && response.status === 200 && response.data && response.data.message === 'Success!') {
                    this.onUserPersonaFunction(this.state.data, response?.data?.body);
                }
            }).catch((err) => {
                console.log(err);
                if (err && err.response && err.response.data) {
                    this.setState({
                        isLoaded: true,
                        error: { message: err.response.data.message, err: err.response }
                    });

                }
            });
        }

        setTimeout(() => {
            this.setState({
                error: {}
            });
        }, 5000);

        setTimeout(() => {
            this.setState({
                errors: {}
            });
        }, 5000);

    };

    onUserPersonaFunction = (data, pageRedirection) => {
        const donationConfirm = async () => {
            const swalval = await ErorrMsgPopUp.fire(userUpdatePersona());
            if (swalval && swalval.value) {
                data.pageRedirection = pageRedirection;
                this.props.history.push({
                    pathname: '/personal', state: { signUpResponse: data }
                })
            } else {
                this.skipInfo(pageRedirection);
            }
          };
        donationConfirm();
    }

    skipInfo = (pageRedirection) => {
        ErorrMsgPopUp.clickConfirm();
        const formData = new FormData();
        let postBody = {
            "userId": this.state.userData.userId,
            "skills": [],
            "interest": [],
            "persona": '',
            "userAgent": userAgentPostData()
        };
        formData.append('info', JSON.stringify(postBody));
        axios({
            method: 'post',
            url: process.env.REACT_APP_userServiceURL + '/user/update/personal/info',
            headers: {'Content-Type': 'multipart/form-data'},
            data: formData,
            withCredentials: true
        }).then((response) => {
            let res = response.data;
            if (res.message === 'Success!') {
                let data = this.state.userData;
                data.userDataPending = res.body.userDataPending;
                data.type = res.body.type;
                authService.updateUserData(data);
                // {
                //     process.env.REACT_APP_environment === 'prod' && window.gtag('config', 'AW-713421902')
                //     process.env.REACT_APP_environment === 'prod' && window.gtag('event', 'conversion', {'send_to': 'AW-713421902/_puYCOuHg_UCEM7ol9QC'});
                // }
                if(pageRedirection === "WPOND") {
                    this.props.history.push({
                        pathname: `/people-of-nature-award/${this.props?.yearData?.status === "UPCOMING" ? "upcoming" : "event"}/${this.props?.yearData?.year}`
                    })
                } else if(this.state.data?.initialSignUpRecord) {
                    authService.clearData();
                    let url = process.env.REACT_APP_myWenatUrl;
                    if(process.env.REACT_APP_environment === "uat") {
                        url = "https://my.uat.wenat.beegram.net";
                    } else if(process.env.REACT_APP_environment === "prod") {
                        url = "https://my.wenaturalists.com";
                    }
                    window.location.href = `${url}`;
                } else {
                    window.location.href = "/";
                }
            }
        }).catch((err) => {
            console.log(err);
            if (err && err.response && err.response.data) {
                this.setState({
                    isLoaded: true,
                    error: {message: err.response.data.message, err: err.response}
                });

                authService.clearData();
            }
        });

    };

    render() {
        const { error } = this.state;

        if (!this.state.userData || !this.state.userData.userId) {
            return <Redirect to='/login' />
        } else {
            return <section className="signup-wrapper">
                <div className="signup-container pt-3">{!window.ReactNativeWebView ?
                    <Link to="/login" className="goback"> Go Back</Link> : null}
                    <div className="signup-box text-center">
                        <div className="signup-boxinn userdtls">
                            {window.ReactNativeWebView ? <img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" className="logotop" /> :
                            <Link to="/"><img src="https://cdn.dscovr.com/images/logoWeNaturalists.svg" alt="" className="logotop" /></Link>}
                            <h1 className="signup-header-text">Welcome, <span className="default-caps">{this.state.userData.firstName}</span>!</h1>
                            <p>Add your address</p>
                            <div className="signup-form-container">
                                <form onSubmit={this.handleSubmit}>
                                    {/*{this.state.userData.isIndianResident ?
                                        <div className="chphonefield form-group">
                                            <input type="number" className="custom-input form-control"
                                                value={this.state.userData.phone ? this.state.userData.phone : null}
                                                disabled={true}
                                                id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                                                placeholder="Phone No." required />
                                            <span className="ccode">+91</span>
                                        </div> :
                                        <div className="form-group">
                                            <input type="text" className="custom-input form-control"
                                                value={this.state.data.email ? this.state.data.email : null}
                                                disabled={true}
                                                id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                                                placeholder="Email." required />
                                        </div>
                                    }*/}
                                    <div className="form-group">
                                        <input type="text" className="custom-input form-control"
                                               value={this.state.data.email ? this.state.data.email : null}
                                               disabled={true}
                                               id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                                               placeholder="Email." required />
                                    </div>
                                    {this.getCountryList()}
                                    {this.getStateList()}
                                    {this.getCitiesList()}

                                    <button type="button" onClick={this.handleSubmit}
                                        className="login-btn btn btn-primary">Next
                                    </button>
                                </form>
                                {error &&
                                    <span className="invalid-feedback" style={{ display: "block" }}> {error.message} </span>}
                            </div>
                        </div>
                    </div>

                    <FooterDiv />
                </div>
            </section>
        }

    }
}

const mapStateToProps = (state) => {
    return {
        yearData: state.WpondReducer?.yearData

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWpondYearData: (state) => dispatch(setWpondYearData(state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAddress);
