import React, { Component } from 'react'
import { authService } from './auth';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as _ from "lodash";
class ProfileCompletionPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: authService.getUserDetailsFromLocalStorage(),
            creditUserFlag: this.props.creditUserFlag,
            profileCompletionReminderData: props?.profileCompletionReminderData
        }
    }

    componentWillUnmount() {
        window.$("#profCompletePop").modal("hide");
    }

    componentWillReceiveProps(props) {
        if(props.profileCompletionReminderData?.userId) {
            this.setState({
                profileCompletionReminderData: props?.profileCompletionReminderData
            })
        }
    }

    closeModal = () => {
        window.$('#profCompletePop').modal('hide');
        if(!this.state.creditUserFlag) {
            window.$('#addpostpop').modal('show');
        } else  if(this.props.onCreditUserPopUp) {
            this.setState({ creditUserFlag: false })
            this.props.onCreditUserPopUp();
        }
    }

    render() {
        const { userData, redirectProfile, profileCompletionReminderData } = this.state;
        return ( 
            <>
                {redirectProfile && <Redirect to={{ pathname:  `/profile-edit/${userData?.customUrl}` }} />}
                <div id="profCompletePop" className="modal fade dgpopup openPostbox" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="popsharescreenlist pfComplteModalWrap ">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <i className="iCross" />
                                    </button>
                                    
                                    <div className="pfComplteProgressBar">
                                        <h3 className="progress-completed">Profile Completed <strong>{profileCompletionReminderData?.points?.point}%</strong></h3>
                                        <div className="progress active">
                                            <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow={profileCompletionReminderData?.points?.point} aria-valuemin="0" aria-valuemax="100" style={{width : `${profileCompletionReminderData?.points?.point}%`}} />
                                        </div>
                                        <p>Update your profile to help the community discover you and receive personalized recommendations.</p>
                                        <div className='bottpostpopbody'>
                                            <div className='bottpostpopright'>
                                                <button type={"button"} className="btn btnPrimary" onClick={() => { this.setState({ redirectProfile: true }) }}>Update Profile</button>
                                                <button className="btn cancel ml-2" type="button" onClick={this.closeModal}>Later</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>   
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profileCompletionReminderData:  state.ProfileReducer?.profileCompletionReminderData
    };
};

export default connect(mapStateToProps)(ProfileCompletionPopup);