import React from 'react';
import { Redirect } from 'react-router-dom';
import * as _ from "lodash";
import axios from 'axios';
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { authService } from '../auth';
import TimeAgo from 'javascript-time-ago'
import en from "javascript-time-ago/locale/en";
import uuid from "react-uuid";
import { DeviceUUID } from 'device-uuid';
import * as FileSaver from "file-saver";
import stripHtml from 'string-strip-html';
import $, { event } from "jquery";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const logInUserId = authService.getUserDetailsFromLocalStorage()?.userId;

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

export const findSiteUrl = (url, matchRegex) => {
    if (matchRegex) {
        let urlValid = matchRegex.test(url);
        if (urlValid) {
            return true;
        }
        return false;
    }
    return false;
}

export const getCDNUrl = (url) => {
    if (process.env.REACT_APP_CDN_ENABLED === 'enabled'
        && process.env.REACT_APP_DSCOVR_ASSETS_S3_BUCKET_URL !== null
        && process.env.REACT_APP_DSCOVR_ASSETS_S3_BUCKET_URL !== undefined
        && process.env.REACT_APP_DSCOVR_ASSETS_CDN_BUCKET_URL !== null
        && process.env.REACT_APP_DSCOVR_ASSETS_CDN_BUCKET_URL !== undefined
        && url !== null
        && url !== undefined) {
        url = url.replace(process.env.REACT_APP_DSCOVR_ASSETS_S3_BUCKET_URL, process.env.REACT_APP_DSCOVR_ASSETS_CDN_BUCKET_URL);
    }
    return url;
};

export const getOptimizedImageQuill = (personalInfo) => {
    if (
        personalInfo.resizedProfileImages &&
        personalInfo.resizedProfileImages["200x200"] != null
    ) {
        return personalInfo.resizedProfileImages["200x200"];
    }
    return personalInfo.profileImage;
}


export const getOptimizedImage = (personalInfo, imageFieldName) => {
    if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['200x200'] != null) {
        return personalInfo.resizedProfileImages['200x200'];
    } else if (personalInfo.resizedProfileImages && personalInfo.resizedProfileImages['400x400'] != null) {
        return personalInfo.resizedProfileImages['400x400'];
    }
    return personalInfo[imageFieldName];
}

export const get720pTranscodedVideo = (attachment) => {
    if (attachment.transcodedVideos && attachment.transcodedVideos['Generic_720p'] != null) {
        return attachment.transcodedVideos['Generic_720p'];
    }
    return attachment['attachmentUrl'];
}

export const convertUnicode = (input) => {
    if (!input) {
        return input;
    }
    let output = input.replace(/%u(\w{4,4})/g, function (a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
    output = output.replace(/\\u(\w{4,4})/g, function (a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
    return output.charAt(0).toUpperCase() + output.slice(1);
    return output;
}

export const dateTimeConvert = (utcSeconds) => {
    const utcSecond = utcSeconds;
    const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    return d.setUTCSeconds(utcSecond / 1000);
}

export const nFormatter = (num, digits) => {
    if (!num) {
        return num
    }
    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const isWebView = () => {
    let userAgent = navigator.userAgent
    let mobileandroid = /wenat_android/.test(userAgent)
    let mobileios = /wenat_ios/.test(userAgent)
    return (mobileios || mobileandroid)
}

export const isMobileDevice = () => {
    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|wenat_android|wenat_ios/i.test(navigator.userAgent);
    //ipad detection
    if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        isMobile = true;
    }
    return isMobile
}

export const postMsg = (payloadtype, payload) => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: payloadtype, data: payload }))
}


export const trimDescription = (item) => {

    var trimmed = item.split('^^__').join(' ').indexOf('@@@__')

    var str = item.split('^^__').join(' ').indexOf(' ', item.split('^^__').join(' ').indexOf('@@@__'))

    var sub = item.substring(trimmed, str)

    item = item.replace(' ' + sub, '')
    item = item.replace('@@@^^^', ' ')
    item = item.replace('@@@__', ' ')
    item = item.replace('  ', '')

    item = item.replace(/&nbsp;/g, ' ')
    item = item.replace(/<br\s*[\/]?>/gi, '\n')

    const regex = /(<([^>]+)>)/ig
    item = item.replace(regex, '')

    return item.split('^^__').join(' ')
}

export const redirectionNomieeDetails = (id, year) => {
    axios({
        method: 'GET',
        url: `${process.env.REACT_APP_userServiceURL}/wpondNomination/getNomination?id=${id}&year=${year}`,
        withCredentials: true
    }).then(response => {
        if (response && response.data && response.data.status === "200 OK") {
            let nomieeId = response.data.body;
            if (nomieeId) {
                window.location.href = `/people-of-nature-award/upcoming/${year}/${nomieeId}`;
            }
        }
    }).catch((err) => {
        console.log(err);
    });
}

export const pointCalculationUpdate = (userId) => {
    axios({
        method: 'GET',
        url: `${process.env.REACT_APP_userServiceURL}/pointcalculation/update?userId=${userId}`,
        withCredentials: true
    }).then(response => {
        if (response && response.data && response.data.status === "200 OK") {

        }
    }).catch((err) => {
        console.log(err);
    });
}

export const utcTodate = (date) => {
    return new Date(date).toLocaleDateString();
};

export const projectTypeData = [{ "name": "All", "value": "All" }, { "name": "Jobs", "value": "Job" }, { "name": "Assignments", "value": "Assignment" }, { "name": "Events", "value": "Event" }, { "name": "Trainings", "value": "Training" }, { "name": "Expeditions", "value": "Expedition" }, { "name": "Fund Raise", "value": "Fund Raise" }];

export const projectFromTypeList = [{ "name": "Job", "value": "job" }, { "name": "Assignment", "value": "assignment" }, { "name": "Event", "value": "event" }, { "name": "Training", "value": "training" }, { "name": "Expedition", "value": "expedition" }, { "name": "Fund Raise", "value": "fundraise" }, { "name": "Story Book", "value": "storybook" }];

export const opportunityFilterType = ["Name", "Location", "Title"];

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const studioFilterType = [{ label: "Name", value: "name" }, { label: "Title", value: "title" }, { label: "Project Title", value: "projectTitle" }, { label: "Hashtags", value: "hashTags" }, { label: "Keywords", value: "metaKeyWords" }];

export const filterOptionList = ["All", "Recent", "Trending"];

export const redirectToProfile = (customUrl, userId, userType, logInUserId) => {
    // if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({
    //         type: "redirectToProfile",
    //         userId: userId,
    //         userType: userType,
    //         id: logInUserId,
    //     }));
    //     window.location.href = "/#";
    // } else {
    //     window.location.href = "/profile/" + customUrl;
    // }
    window.location.href = `/${userType === 'CIRCLE' ? 'community' : 'profile'}/${customUrl}`
}

export const redirectToCircle = (slug) => {
    // if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({
    //         type: "CIRCLE",
    //         url: slug,
    //     }));
    //     window.location.href = "/#";
    // } else {
    //     window.location.href = "/community/" + slug
    // }
    window.location.href = "/community/" + slug
}

export const redirectToHashTags = (hashTag) => {
    // if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({
    //         type: "hashTagDetails",
    //         hashTag: hashTag,
    //     }));
    //     window.location.href = "/#";
    // } else {
    //     window.location.href = '/explore-hashtag/' + hashTag
    // }
    window.location.href = '/explore-hashtag/' + hashTag
}

export const detailsPageRedirection = (postId) => {
    axios({
        method: 'GET',
        url: `${process.env.REACT_APP_userServiceURL}/${authService.isAuthenticated() ? "backend/post" : "backend/unauth"}/getPostId?postId=${postId}`,
        withCredentials: true
    }).then(response => {
        if (response && response.data && response.data.status === "200 OK") {
            window.location.href = response.data.body ? `/studio/${response.data.body}` : `/post/${postId}`;
        }
    }).catch((err) => {
        console.log(err);
    });
}

export const postNotFoundFunction = (id) => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then(() => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <p>This Post No Longer Exists</p>,
            didClose: () => {
                window.location.href = `/post/${id}`;
            }

        })
    })
}

export const matchPostLinkUrl = (url) => {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p) && url.indexOf("&list") !== -1) {
        return url.split("&list")[0];
    }
    return url;
}

export const projectDeleteErrorMsgFunc = (type) => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then(() => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <p>{`You cannot ${type === "DELETE" ? 'delete' : 'cancel'} the project that has active subscribers.`}</p>,
        })
    })
}

export const getDeviceId = () => {
    var du = new DeviceUUID().parse();
    var dua = [
        du.language,
        du.platform,
        du.os,
        du.cpuCores,
        du.isAuthoritative,
        du.silkAccelerated,
        du.isKindleFire,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isWindows,
        du.isLinux,
        du.isLinux64,
        du.isMac,
        du.isiPad,
        du.isiPhone,
        du.isiPod,
        du.isSmartTV,
        du.pixelDepth,
        du.isTouchScreen,
    ];
    return dua.join("-");
}

export const userAgentGetData = (first) => {
    const Deviceuuid = new DeviceUUID().get() + '-' + uuid();

    // let ID = "";
    // if (localStorage.getItem('rzp_device_id')) {
    //     DeviceUuid = localStorage.getItem('rzp_device_id');
    //     ID = DeviceUuid.split('.')[1];
    // } else {
    //     DeviceUuid = getDeviceId();
    //     ID = DeviceUuid;
    // }
    if (!localStorage.getItem("deviceuuID")) {
        localStorage.setItem("deviceuuID", Deviceuuid);
    }
    let userAgent = { "mobile": isMobileDevice(), "deviceId": localStorage.getItem("deviceuuID"), "latitude": localStorage.getItem('latitude'), "longitude": localStorage.getItem('longitude'), "ip": localStorage.getItem("ip") };
    let result = (first ? '?' : '&') + new URLSearchParams(userAgent).toString();
    return result;
}

export const userAgentPostData = () => {
    const Deviceuuid = new DeviceUUID().get() + '-' + uuid();
    // let DeviceUuid = '';
    // let ID = "";
    // if (localStorage.getItem('rzp_device_id')) {
    //     DeviceUuid = localStorage.getItem('rzp_device_id');
    //     ID = DeviceUuid.split('.')[1];
    // } else {
    //     DeviceUuid = getDeviceId();
    //     ID = DeviceUuid;
    // }
    if (!localStorage.getItem("deviceuuID")) {
        localStorage.setItem("deviceuuID", Deviceuuid);
    }
    let userAgent = { "mobile": isMobileDevice(), "deviceId": localStorage.getItem("deviceuuID"), "latitude": localStorage.getItem('latitude'), "longitude": localStorage.getItem('longitude'), "ip": localStorage.getItem("ip") };
    return userAgent;
}

export const renderTaggedUsers = (text) => {
    if (!text || text.length === 0) {
        return text;
    }
    text = text.split("@@@__").join('<a href="/profile/');
    text = text.split("^^__").join('">');
    text = text.split("&&__").join('">');
    text = text.split("&amp;&amp;__").join('">');
    text = text.split("&amp;&amp;__").join('">');
    text = text.split("@@@^^^").join("</a>");
    text = text
        .split("###__")
        .join(
            '<a class="hashLinkPost" style="cursor:pointer" href="/explore-hashtag/'
        );
    text = text
        .split("&&__")
        .join(
            '<a class="hashLinkPost" style="cursor:pointer" href="/explore-hashtag/'
        );
    text = text.split("###^^^").join("</a>");
    text = text.replace(/#\s/g, "");
    return text;
};

export function getFormattedDate(milliseconds) {
    var utcSeconds = milliseconds;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds / 1000);
    return timeAgo.format(d, "mini-now");
}

export const checkAndEditproject = (projectDetails, deactivationConfig, history) => {
    if (deactivationConfig && deactivationConfig.profileDeactivated) {
        ErorrMsgPopUp.fire({
            didOpen: () => {
                ErorrMsgPopUp.clickConfirm();
            }
        }).then(() => {
            return ErorrMsgPopUp.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                title: <p>{`The project that has active subscribers.`}</p>,
            })
        })
    } else {
        history.push({
            pathname: '/project/edit-projects',
            state: {
                entityId: projectDetails.projectId,
                type: projectDetails.secondaryEntityType.toLowerCase(),
                isOffLine: projectDetails.project?.isOffLine || projectDetails?.isOffLine,
                isOfflineProject: projectDetails.project?.isOfflineProject || projectDetails?.isOffLine,
                running: (projectDetails.stage === "Running") ? true : false
            }
        })
    }
}

export const checkedValueData = (data, additionalFieldsList) => {
    const index = additionalFieldsList && additionalFieldsList.findIndex(p => p === data);
    if (index > -1) {
        return true;
    }
    return false;
}

export const restrictedPageRedirection = () => {
    window.location.href = "/restricted-view";
}


export const redirectToProfilePage = (customUrl, partyType) => {
    if (partyType === "CIRCLE") {
        window.location.href = "/community/" + customUrl;
    } else {
        window.location.href = "/profile/" + customUrl;
    }
}

export const awardPageRedirection = (notLoginSuccess) => {
    let url = `${process.env.REACT_APP_userServiceURL}/wpondAbout/getUpcomingYear`;
    if (notLoginSuccess) {
        url = `${process.env.REACT_APP_userServiceURL}/unauth/wpondAbout/getUpcomingYear${userAgentGetData(true)}`
    }
    axios({
        method: "GET",
        url: url,
        withCredentials: true,
    })
        .then((response) => {
            if (
                response &&
                response.data &&
                response.data.message === "Success!"
            ) {
                let year = new Date().getFullYear();
                window.location.href = response.data.body ? `/people-of-nature-award/upcoming/${response.data.body}` : `/people-of-nature-award/event/${year}`;
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const adminFlagData = () => {
    const urlParams = new URLSearchParams(window.location.search && window.location.search);
    const adminFlag = urlParams.get('admin');
    return adminFlag;
}

export const projectPageRedirection = () => {
    // if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({
    //         type: "PROJECT",
    //     }));
    //     window.location.href = "/#";
    // } else {
    //     window.location.href = "/project";
    // }
    window.location.href = "/project";
}

export const studioPageRedirection = (studioId) => {
    axios({
        method: 'GET',
        url: `${process.env.REACT_APP_userServiceURL}/paidContentPost/getStudioPostById?studioId=${studioId}`,
        withCredentials: true
    }).then(response => {
        if (response && response.data && response.data.status === "200 OK") {
            window.location.href = `/studio/${response.data.body}`;
        }
    }).catch((err) => {
        console.log(err);
    });
}

export const totalVoteCount = (voteList) => {
    return voteList && voteList.reduce((n, { totalVote }) => n + totalVote, 0);
}

export const doubleHashTagsFunc = (content) => {
    var re = /(?:^|\W)#(\w+)(?!\w)/g, match;
    let hashTagsList = [];
    while (match = re.exec(content)) {
        hashTagsList.push(match[1]);
    }

    let allHashTagsList = hashTagsList;
    hashTagsList = [...new Set(allHashTagsList)];
    return hashTagsList;
};

export const doubleUserTagsFunc = (content) => {
    let user = content.match(/href="([^"]+profile[^"]+)"/g);
    let userList = [];
    user && user.map((item) => {
        item = item.split('href="/profile/');
        if (item.length > 1) {
            userList.push(item[1].replace('"', ''));
        }
    })
    return userList;
};

export const getOptimizedCoverImage = (personalInfo) => {
    if (window.innerWidth > 1000 && personalInfo.resizedCoverImages && personalInfo.resizedCoverImages['1000x1000'] != null) {
        return personalInfo.resizedCoverImages['1000x1000'];
    } else if (window.innerWidth > 600 && window.innerWidth <= 1000 && personalInfo.resizedCoverImages && personalInfo.resizedCoverImages['600x600'] != null) {
        return personalInfo.resizedCoverImages['600x600'];
    } else if (window.innerWidth > 320 && window.innerWidth <= 600 && personalInfo.resizedCoverImages && personalInfo.resizedCoverImages['400x400'] != null) {
        return personalInfo.resizedCoverImages['400x400'];
    } else {
        return personalInfo.coverImage;
    }
}

export const getOptimizedArticleImage = (attachment) => {
    if ((window.innerWidth > 1000 || (window.innerWidth > 600 && window.innerWidth <= 1000)) && attachment.resizedImages && attachment.resizedImages['1000x1000'] != null) {
        return attachment.resizedImages['1000x1000'];
    } else if (window.innerWidth > 320 && window.innerWidth <= 600 && attachment.resizedImages && attachment.resizedImages['800x800'] != null) {
        return attachment.resizedImages['800x800'];
    }
    return attachment.attachmentUrl;
}

export const getOptimizedCircleCoverImage = (circleInfo) => {
    if (circleInfo.resizedCoverImages && circleInfo.resizedCoverImages['300x300'] != null) {
        return circleInfo.resizedCoverImages['300x300'];
    }
    return circleInfo.coverImage;
}

export const getOptimizedCauseCoverImage = (causesInfo) => {
    if (causesInfo.resizedImages && causesInfo.resizedImages['300x300'] != null) {
        return causesInfo.resizedImages['300x300'];
    }
    return causesInfo.imageUrl;
}

export const getOptimizedOrganizationCoverImage = (personalInfo) => {
    if (window.innerWidth > 600 && personalInfo.resizedCoverImages && personalInfo.resizedCoverImages['600x600'] != null) {
        return personalInfo.resizedCoverImages['600x600'];
    } else if (window.innerWidth > 320 && window.innerWidth <= 600 && personalInfo.resizedCoverImages && personalInfo.resizedCoverImages['400x400'] != null) {
        return personalInfo.resizedCoverImages['400x400'];
    } else {
        return personalInfo.coverImage;
    }
}

export const getOptimizedNetworkUserCoverImage = (personalInfo) => {
    if (personalInfo.resizedCoverImages && personalInfo.resizedCoverImages['300x300'] != null) {
        return personalInfo.resizedCoverImages['300x300'];
    } else {
        return personalInfo.coverImage;
    }
}

export const getOptimizedStudioActivityImage = (data) => {
    if (data.resizedCoverImage && data.resizedCoverImage["400x400"] !== null) {
        return data.resizedCoverImage["400x400"];
    }
    return data.coverImage;
}

export const download_file_pdf = (evt, url, documentTitle) => {
    evt.preventDefault();
    var parsedUrl = url.substring(url.lastIndexOf("/") + 1);
    let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);
    FileSaver.saveAs(url, documentTitle ? documentTitle : filename);
};

export const getResizedImage = (attachment) => {
    if (attachment.resizedImages && attachment.resizedImages['300x300'] != null) {
        return attachment.resizedImages['300x300'];
    }
    return attachment.attachmentUrl;
}

export const getName = (data) => {
    if (data === 'PRIVATE') {
        return <><i className="iHide" /> Private</>;
    }
    if (data === 'EVERYONE') {
        return <><i className='iGlobe'></i> On WeNaturalists</>;
    } if (data === 'OUTSIDE') {
        return <><i className='iMegaphone'></i> Everyone</>;
    } if (data === 'CONNECTS') {
        return <><i className='iCommunity'></i> Connects</>;
    } if (data === 'CONNECTS_FOLLOWERS') {
        return <><i className='iTickedUser'></i> Connects & Followers</>;
    } if (data === 'MEMBERS') {
        return <><i className='iVIP'></i> Members</>;
    } if (data === 'MEMBERS_FOLLOWERS') {
        return <><i className='iTickedUser'></i> Members & Followers</>;
    } if (data === 'EMPLOYEES') {
        return <><i className='iAdd_Group'></i> Employees</>;
    } if (data === 'EMPLOYEES_FOLLOWERS') {
        return <><i className='iTickedUser'></i> Employees & Followers</>;
    }
    return <><i className="iHide" /> Private</>
}

// get file name and return icon as per required extension
export const getFileIcon = (fileName) => {
    const extension = fileName && fileName.split('.').pop().toLowerCase();
    switch (extension) {
        case 'pdf':
            return <i className='iPDF' />;
        case 'doc':
        case 'docx':
            return <i className='iword' />;
        case 'xls':
        case 'xlsx':
            return <i className='iXLS' />;
        case 'ppt':
        case 'pptx':
            return <i className='iPPT_New' />;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
            return <i className='iPhotos' style={{ fontSize: '14px', top: '5px' }} />;
        case 'mp3':
        case 'wav':
            return <i className='iAudio' />;
        case 'mp4':
        case 'mov':
            return <i className='iVid' />;
        default:
            return <i className='iEdition2' />;
    }
}


export const projectDetailsPageRedirection = (slug) => {
    // if (window.ReactNativeWebView) {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({
    //         type: "PROJECT",
    //         slug: slug
    //     }));
    //     window.location.href = "/#";
    // } else {
    //     window.location.href = `/project/feeds/${slug}`;
    // }
    window.location.href = `/project/feeds/${slug}`;
}

export const convertCurrencySystem = (labelValue) => {
    return Math.abs(Number(labelValue)) >= 1.0e+9 ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B" :
        Math.abs(Number(labelValue)) >= 1.0e+6 ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M" :
            Math.abs(Number(labelValue)) >= 1.0e+3 ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K" : Math.abs(Number(labelValue));
}

export const showErrorMessage = (msg) => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then(() => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <p>{msg}</p>,
        })
    })
}

export const textToSpeech = (postId, content) => {
    let data = {
        postId: postId,
        content: stripHtml(content.replace(/data-value="[^"]*"/g, "").replace(/data-link="[^"]*"/g, "").replace(/@/g, "")).replace(/#\s+/g, ''),
        type: "USER_BLOG"
    }
    axios({
        method: 'POST',
        url: process.env.REACT_APP_userServiceURL + '/recommend/audiopreprocess',
        data: data,
        withCredentials: true
    }).then((response) => {
        if (response?.data) {

        }
    }).catch((error) => {
        console.log("Error", error)
    })
}

export const audioTimeFormat = (duration) => {
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;
    var finalValue = "";
    if (hrs > 0) {
        finalValue += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    finalValue += "" + mins + ":" + (secs < 10 ? "0" : "");
    finalValue += "" + secs;
    return finalValue;
}

export const blockUserMessage = (type, message) => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then(() => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <p>{type !== "MESSAGE" ? "Permissions Denied" : message}</p>,
        })
    })
}

export const getEnviormentRegex = () => {
    if (process.env.REACT_APP_environment === 'dev') {
        return /dev.wenat.beegram.in\/profile|community/gi;
    } else if (process.env.REACT_APP_environment === 'uat') {
        return /uat.wenat.beegram.net\/profile|community/gi;
    } else if (process.env.REACT_APP_environment === 'prod') {
        return /wenaturalists.com\/profile|community/gi;
    }
    return "";
}

export const ogStaticImagePathFn = ["https://cdn.dscovr.com/images/trophyNewWHITE-og.png", "https://cdn.dscovr.com/images/social-share-icon2.png", "https://cdn.dscovr.com/images/wppd-og.png", "https://cdn.dscovr.com/images/Audio.png", "https://cdn.dscovr.com/images/banner-explore-blog-small-new.png", "https://cdn.dscovr.com/images/Link.png", "https://cdn.dscovr.com/images/video-blank2.png", "https://cdn.dscovr.com/images/Photo.png", "https://www.facebook.com/images/fb_icon_325x325.png"];

export const checkImageSizeFn = (imagePath) => {
    let image = new Image();
    image.src = imagePath;
    image.onload = function () {
        if (image.width < 200 && image.height < 200) {
            return true
        } else {
            return false;
        }
    };
}

const renderTaggedUsersFunc = (text) => {
    if (!text || text.length === 0) {
        return text;
    }
    if (text.includes("# # ")) {
        text = text.replace("# # ", "");
    }
    text = text.split("@@@__").join("<a href=\"/profile/");
    text = text.split("^^__").join("\">")
    text = text.split("&&__").join("\">")
    text = text.split("&amp;&amp;__").join("\">")
    text = text.split("&amp;&amp;__").join("\">")
    text = text.split("@@@^^^").join("</a>");
    text = text.split("###__").join("#<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/");
    text = text.split("&&__").join("#<a class=\"hashLinkPost\" style=\"cursor:pointer\" href=\"/explore-hashtag/");
    text = text.split("###^^^").join("</a>");
    text = text.replace(/(<([^>]+)>)/ig, '');
    return text;
}

export const commonOgSection = (linkdata, og_data, flag) => {

    return (
        <div className={flag ? "ownLink" : ""}>
            <div className="customLinkPost" onClick={() => { window.open(linkdata, "_blank") }}>
                <figure><img src={og_data?.image} alt={og_data?.title} /></figure>
                <div className="customLinkPostDesc">
                    <h5>{og_data?.title}</h5>
                    <p>{renderTaggedUsersFunc(og_data?.description)}</p>
                    {/* <p>{linkdata}</p> */}
                </div>
            </div>
        </div>
    )
}

export const videoThumbnailFunction = (data, circleFlag) => {
    return data.thumbnail ? data.thumbnail : !circleFlag ? data.attachmentIds[0].thumbnails[0] : data.attachments[0].thumbnails[0];
}

export const defaultImageSection = (type) => {
    return type === "BUSINESS_PAGES" || type === "COMPANY" ? "https://cdn.dscovr.com/images/DefaultBusiness.png" : type === "CIRCLE" ? "https://cdn.dscovr.com/images/circleDefault.png" : "https://cdn.dscovr.com/images/users1.png"
}

export const commonCommentSection = (currentUserDetails) => {
    return (
        <figure className="userlogo">
            <img src={currentUserDetails?.profileImage != null && currentUserDetails?.profileImage !== "" ? getCDNUrl(currentUserDetails.profileImage) : currentUserDetails?.type === "INDIVIDUAL" ? "https://cdn.dscovr.com/images/users1.png" : currentUserDetails?.type === "CIRCLE" ? "https://cdn.dscovr.com/images/circleDefault.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"} alt="" />
        </figure>
    )
}

export const redirectToProjectPage = (slug, userId, requestingUserId, history) => {
    axios({
        method: 'get',
        url: `${process.env.REACT_APP_userServiceURL}/profile/isvalidsuperAdmin?requestingUserId=${requestingUserId}&companyId=${userId}`,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    }).then(response => {
        if (response && response.data && response.data.status === '200 OK') {
            history.push({
                pathname: '/project/feeds/' + slug,
                state: {
                    userId: response.data.body ? userId : requestingUserId
                }
            })
        }
    }).catch((err) => {
        console.log(err);
    });
}

export const getDocumentCoverImage = (data) => {
    if (data.attachmentIds?.length > 0 && data.attachmentIds[0].relatedImages?.length > 0) {
        return data.attachmentIds[0].relatedImages[0]
    }
    return data.attachmentType === "PPT" ? "https://cdn.dscovr.com/images/ppt_icon.webp" : "https://cdn.dscovr.com/images/pdf_icon.webp"
}

export const getDownloadFileName = (name) => {
    return name && name.replace(/ /g, "_");
}

export const convertMsToTime = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;
    return `${hours.toString().padStart(2, '0')} hr. ${minutes.toString().padStart(2, '0')} min. ${seconds.toString().padStart(2, '0')} sec.`;
}

export const employeeRequestModalSwal = (type, name, entity) => {
    return ({
        title: `${type === "CIRCLE" && entity?.permission ? `Permission to Raise Funds in future will be denied as a member of ${name} community. ${name} community restricts its members to raise funds in future. Are you sure you want to ${entity?.type?.toLowerCase()} membership for the community ${name}` : type === "COMPANY" && entity?.permission ? `Permission to Raise Funds in future will be denied as an employee of ${name}. ${name} restricts its employees to raise funds in future. Are you sure you want to ${entity?.type?.toLowerCase()} employee request for the organization ${name}` : `Are you sure you want to ${type !== "COMPANY" ? "request membership for the community" : "send an employee request to the organization"} ${name}`}?`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};

export const creditPointsUseSwal = () => {
    return ({
        title: `Are you sure you do not want to use your credit points?.`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Go Back',
        allowOutsideClick: false,
    })
};

export function greedyNavcalcWidth() {
    //var navwidth = window.$('.greedy-nav');
    var navwidth = 0;
    var morewidth = window.$('.greedy-nav .nav .more').outerWidth(true);
    window.$('.greedy-nav .nav > li:not(.more)').each(function () {
        navwidth += window.$(this).outerWidth(true);
    });

    //var availablespace = $('nav').outerWidth(true) - morewidth;
    var availablespace = window.$('.greedy-nav').width() - morewidth;

    if (navwidth > availablespace) {
        var lastItem = window.$('.greedy-nav .nav > li:not(.more)').last();
        lastItem.attr('data-width', lastItem.outerWidth(true));
        lastItem.prependTo(window.$('.greedy-nav .nav .more ul'));
        greedyNavcalcWidth();
    } else {
        var firstMoreElement = window.$('.greedy-nav .nav li.more li').first();
        if (navwidth + firstMoreElement.data('width') < availablespace) {
            firstMoreElement.insertBefore(window.$('.greedy-nav .nav .more'));
        }
    }

    if (window.$('.more li').length > 0) {
        window.$('.more').css('display', 'block');
    } else {
        window.$('.more').css('display', 'none');
    }
};

export function greedyNavcalcWidthExtraFn() {
    window.$('.more button').click(function () {
        window.$('.hidden-links').slideToggle();
        window.$(this).toggleClass('open');
    });

    if (window.$(".greedy-nav .nav .dropdown .nav-item a").hasClass('active')) {

        // Get menu parent and add active class
        var parent = window.$('.greedy-nav .nav .dropdown')
        parent.addClass('active');

        // Remove added class
        window.$('.dropdown-menu').mouseleave(function () {
            parent.removeClass('active');
        });
    }
}

export const fundRaiseRestrictOrg = [{ label: "None", value: "NONE" }, { label: "Admin", value: "ADMIN" }, { label: "Employee", value: "EMPLOYEES" }, { label: "Admin & Employee", value: "ADMINANDEMPLOYEES" }]

export const fundRaiseRestrictCircle = [{ label: "None", value: "NONE" }, { label: "Admin", value: "ADMIN" }, { label: "Member", value: "MEMBER" }, { label: "Admin & Member", value: "ADMINANDMEMBER" }]

export const fundRaiseRestrictionMessage = () => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then((result) => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <><p>You cannot create a Fund Raise project as an individual</p></>,
        })
    })
}

export const isYouTubeLink = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=([^&\s]+)|embed\/([^\/\s]+)|([^\/\s]+))$/;
    return regex.test(url);
}

export const projectPostedTimeSection = (time, type) => {
    return <>{type === "POSTED" ? "Posted" : "Completed"} on <strong>{new Date(time).toLocaleDateString('en-GB', {year: 'numeric'}).replace(/ /g, '-') === new Date().getFullYear().toString() ? new Date(time).toLocaleDateString('en-GB', {day: '2-digit', month: 'short'}).replace(/ /g, '-') : new Date(time).toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/ /g, '-')}</strong></>
}

export const getVolunterProjectName = (secondaryEntityType) => {
    if (secondaryEntityType.toUpperCase() === "JOB") {
        return "VOLUNTEERING JOB";
    } else if (secondaryEntityType.toUpperCase() === "ASSIGNMENT") {
        return "VOLUNTEERING ASSIGNMENT";
    } else if (secondaryEntityType.toUpperCase() === "EVENT") {
        return "VOLUNTEERING EVENT";
    } else if (secondaryEntityType.toUpperCase() === "TRAINING") {
        return "VOLUNTEERING TRAINING";
    } else if (secondaryEntityType.toUpperCase() === "EXPEDITION") {
        return "VOLUNTEERING EXPEDITION";
    } else if (secondaryEntityType.toUpperCase() === "STORYBOOK") {
        return "VOLUNTEERING STORYBOOK";
    } else if (secondaryEntityType.toUpperCase() === "FUNDRAISE") {
        return "VOLUNTEERING FUND RAISE";
    }
}

export const getUnsubscribeMessage = (code) => {
    if (code === '17482') {
        return "To unsubscribe from transactional emails you have to discontinue your WeNaturalists membership.";
    }

    return "You have been successfully unsubscribed. You will only receive transactional emails."
}

export const getAudioDuration = (src, cb) => {
    var audio = new Audio();
    $(audio).on("loadedmetadata", function(){
        cb(audio.duration);
    });
    audio.src = src;
}

export const getPercentageData = (totalValue, percentageValue) => {
    return (totalValue * percentageValue) / 100;
}

export const communityPattern = /^community/i;

export const projectPattern = /^project/i;

export const getPostDataConfig = (entityId, type, sendRequestMemberShip) => {
    let url = "";
    if (type === "CAUSE") {
        url = `backend/public/cause/joined/list?userId=${logInUserId}&page=0&size=1000`;
    } else if (type === "PROJECT") {
        url = `backend/getProjects/${logInUserId}?excludeJobs=true&requestingUserId=${logInUserId}`;
    } else {
        url = `circle/get/list-for-post/${logInUserId}`;
    }
    axios({
        method: "get",
        url: `${process.env.REACT_APP_userServiceURL}/${url}`,
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
    }).then((response) => {
        if (response && response.data && response.data.body && response.status === 200) {
            let dataList = type !== "CAUSE" ? response.data.body : response.data.body?.content;
            let data = dataList && dataList.find((data) => data.id === entityId);
            if (!_.isEmpty(data)) {
                window.$("#addpostpop").modal("show");
            } else {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <><p>{type === "CIRCLE" ? "Only members can post in Community. You can also become a member of this Community." : type === "PROJECT" ? "Only participants can post in Project. Connect with project admin to join this project." : `You can not post under this ${type.toLowerCase()}`}</p>{type === "CIRCLE" && sendRequestMemberShip && <a class="styleBtn" href={'javascript:void(0)'} onClick={(e) => { e.preventDefault();  sendRequestMemberShip(); ErorrMsgPopUp.clickConfirm();}}>Request Membership</a>}</>,
                    })
                })
            }
        }
    }).catch((err) => {
        console.log(err);
    });
}

export const getUserDataFromUrlParams = () => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const signUpSource = urlParams.get('signUpSource');
    let userParamsData = {
        signUpSource: signUpSource
    }
    return userParamsData;
}

export const rankFormatFunction = (number) => {
    let suffix = 'th';
    if (number % 10 == 1 && number % 100 != 11) {
        suffix = 'st';
    } else if (number % 10 == 2 && number % 100 != 12) {
        suffix = 'nd';
    } else if (number % 10 == 3 && number % 100 != 13) {
        suffix = 'rd';
    }
    return suffix;
}

export const donationNotificationUrl = (url) => {
    var pattern = /^((http|https|ftp):\/\/)/;
    if(!pattern.test(url)) {
        window.location.href = `${process.env.REACT_APP_domainUrl}/${url}`;
    } 
    window.open(`${url}`, '_blank');
}

export const userUpdatePersona = (emailVerifyFlag, userData, emailAlreadyVerified) => {
    return ({
        title: emailVerifyFlag || emailAlreadyVerified ? <>{!emailAlreadyVerified ? <div className="h3 font-italic">Welcome, <span className={"default-caps orgnzName" + (userData?.type  === "COMPANY" ? " orgnzName" : "")}>{userData?.username}</span>!</div> : <></>}<h5 class="h5">{emailAlreadyVerified ? "Email already verified" : "Email has been successfully verified"}</h5><p>Update your profile to help the community discover you and receive personalized recommendations.</p></> : <p>Update your profile to help the community discover you and receive personalized recommendations.</p>,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: !emailAlreadyVerified,
        confirmButtonText: !emailAlreadyVerified ? 'Update Now' : 'Ok',
        cancelButtonText: 'Later',
        allowOutsideClick: false,
    })
};

export const getUserEmailVerifyTokenUrlParams = () => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const token = urlParams.get('token');
    let userParamsData = {
        verifyToken: token
    }
    return userParamsData;
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};