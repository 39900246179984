export const SET_PERSONAL_PROFILE_DATA= "/profile/SET_PERSONAL_PROFILE_DATA";
export const SET_PERSONAL_PROFILE_DATA_SUCCESS= "/profile/SET_PERSONAL_PROFILE_DATA_SUCCESS";
export const SET_PERSONAL_PROFILE_ADDRESS= "/profile/SET_PERSONAL_PROFILE_ADDRESS";
export const SET_PERSONAL_PROFILE_ADDRESS_SUCCESS= "/profile/SET_PERSONAL_PROFILE_ADDRESS_SUCCESS";
export const SET_PERSONAL_PROFILE_BIO= "/profile/SET_PERSONAL_PROFILE_BIO";
export const SET_PERSONAL_PROFILE_BIO_SUCCESS= "/profile/SET_PERSONAL_PROFILE_BIO_SUCCESS";
export const SET_PERSONAL_PROFILE_SKILLS= "/profile/SET_PERSONAL_PROFILE_SKILLS";
export const SET_PERSONAL_PROFILE_SKILLS_SUCCESS= "/profile/SET_PERSONAL_PROFILE_SKILLS_SUCCESS";
export const SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS= "/profile/SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS";
export const SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS_SUCCESS= "/profile/SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS_SUCCESS";
export const SET_PERSONAL_PROFILE_INTERESTS= "/profile/SET_PERSONAL_PROFILE_INTERESTS";
export const SET_PERSONAL_PROFILE_INTERESTS_SUCCESS= "/profile/SET_PERSONAL_PROFILE_INTERESTS_SUCCESS";
export const SET_PERSONAL_PROFILE_SPECIALITIES= "/profile/SET_PERSONAL_PROFILE_SPECIALITIES";
export const SET_PERSONAL_PROFILE_SPECIALITIES_SUCCESS= "/profile/SET_PERSONAL_PROFILE_SPECIALITIES_SUCCESS";
export const SET_PERSONAL_PROFILE_EDUCATION= "/profile/SET_PERSONAL_PROFILE_EDUCATION";
export const SET_PERSONAL_PROFILE_EDUCATION_SUCCESS= "/profile/SET_PERSONAL_PROFILE_EDUCATION_SUCCESS";
export const SET_PERSONAL_PROFILE_EXPERIENCE= "/profile/SET_PERSONAL_PROFILE_EXPERIENCE";
export const SET_PERSONAL_PROFILE_EXPERIENCE_SUCCESS= "/profile/SET_PERSONAL_PROFILE_EXPERIENCE_SUCCESS";
export const SET_PERSONAL_PROFILE_POPULAR_HASH_TAGS= "/profile/SET_PERSONAL_POPULAR_HASH_TAGS";
export const SET_PERSONAL_PROFILE_POPULAR_HASH_TAGS_SUCCESS= "/profile/SET_PERSONAL_POPULAR_HASH_TAGS_SUCCESS";
export const SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE= "/profile/SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE";
export const SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE_SUCCESS= "/profile/SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE_SUCCESS";
export const SET_PERSONAL_PROFILE_JOINED_CAUSE= "/profile/SET_PERSONAL_PROFILE_JOINED_CAUSE";
export const SET_PERSONAL_PROFILE_JOINED_CAUSE_SUCCESS= "/profile/SET_PERSONAL_PROFILE_JOINED_CAUSE_SUCCESS";
export const SET_PERSONAL_PROFILE_RECENT_ACTIVITY= "/profile/SET_PERSONAL_PROFILE_RECENT_ACTIVITY";
export const SET_PERSONAL_PROFILE_RECENT_ACTIVITY_SUCCESS= "/profile/SET_PERSONAL_PROFILE_RECENT_ACTIVITY_SUCCESS";
export const SET_PERSONAL_PROFILE_BUSINESS_PAGES= "/profile/SET_PERSONAL_PROFILE_BUSINESS_PAGES";
export const SET_PERSONAL_PROFILE_BUSINESS_PAGES_SUCCESS= "/profile/SET_PERSONAL_PROFILE_BUSINESS_PAGES_SUCCESS";
export const SET_PERSONAL_CONTACT_INFO= "/profile/SET_PERSONAL_CONTACT_INFO";
export const SET_PERSONAL_CONTACT_INFO_SUCCESS= "/profile/SET_PERSONAL_CONTACT_INFO_SUCCESS";
export const SET_PROFILE_BASIC_INFO_DETAILS = "/profile/SET_PROFILE_BASIC_INFO_DETAILS";
export const SET_PROFILE_BASIC_INFO_DETAILS_SUCCESS = "/profile/SET_PROFILE_BASIC_INFO_DETAILS_SUCCESS";
export const SET_PROFILE_COMPLETION_REMINDER = "/profile/SET_PROFILE_COMPLETION_REMINDER";
export const SET_PROFILE_COMPLETION_REMINDER_SUCCESS = "/profile/SET_PROFILE_COMPLETION_REMINDER_SUCCESS";
export const RESET_PROFILE_COMPLETION_REMINDER = "/profile/RESET_PROFILE_COMPLETION_REMINDER";


let initialState = {
    personalProfileData: {},
    personalAddress:{},
    personalSkills:{},
    personalSkillsAndSpecializations:{},
    personalSpecialities:{},
    personalEducations:{},
    personalExperience:{},
    personalInterests:{},
    personalHashTags:{},
    personalNotJoinedCauses:{},
    personalJoinedCauses:{},
    personalRecentActivity:{},
    personalBusinessPages:{},
    personalBio:{},
    initiated: {},
    contactInfo:{},
    profileBasicInfo: {},
    profileCompletionReminderData: {}
};

const ProfileReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case "/profile/SET_PERSONAL_PROFILE_DATA":
            state = { ...state, personalProfileData:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_DATA_SUCCESS":
            state = {
                ...state,
                personalProfileData: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_CONTACT_INFO":
            state = { ...state, contactInfo:{} };
            return state;
        case "/profile/SET_PERSONAL_CONTACT_INFO_SUCCESS":
            state = {
                ...state,
                contactInfo: action.payload
            };
            return state;
        case "/profile/SET_PROFILE_BASIC_INFO_DETAILS":
            state = { ...state, profileBasicInfo:{} };
            return state;
        case "/profile/SET_PROFILE_BASIC_INFO_DETAILS_SUCCESS":
            state = {
                ...state,
                profileBasicInfo: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_ADDRESS":
            state = { ...state, personalAddress:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_ADDRESS_SUCCESS":
            state = {
                ...state,
                personalAddress: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_BIO":
            state = { ...state, personalBio:'' };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_BIO_SUCCESS":
            state = {
                ...state,
                personalBio: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_INTERESTS":
            state = { ...state, personalInterests:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_INTERESTS_SUCCESS":
            state = {
                ...state,
                personalInterests: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_SKILLS":
            state = { ...state, personalSkills:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_SKILLS_SUCCESS":
            state = {
                ...state,
                personalSkills: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS":
            state = { ...state, personalSkillsAndSpecializations:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS_SUCCESS":
            state = {
                ...state,
                personalSkillsAndSpecializations: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_SPECIALITIES":
            state = { ...state, personalSpecialities:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_SPECIALITIES_SUCCESS":
            state = {
                ...state,
                personalSpecialities: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_EDUCATION":
            state = { ...state, personalEducations:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_EDUCATION_SUCCESS":
            state = {
                ...state,
                personalEducations: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_EXPERIENCE":
            state = { ...state, personalExperience:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_EXPERIENCE_SUCCESS":
            state = {
                ...state,
                personalExperience: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_POPULAR_HASH_TAGS":
            state = { ...state, personalHashTags:{} };
            return state;
        case "/profile/SET_PERSONAL_POPULAR_HASH_TAGS_SUCCESS":
            state = {
                ...state,
                personalHashTags: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE":
            state = { ...state, personalNotJoinedCauses:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE_SUCCESS":
            state = {
                ...state,
                personalNotJoinedCauses: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_JOINED_CAUSE":
            state = { ...state, personalJoinedCauses:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_JOINED_CAUSE_SUCCESS":
            state = {
                ...state,
                personalJoinedCauses: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_RECENT_ACTIVITY":
            state = { ...state, personalRecentActivity:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_RECENT_ACTIVITY_SUCCESS":
            state = {
                ...state,
                personalRecentActivity: action.payload
            };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_BUSINESS_PAGES":
            state = { ...state, personalBusinessPages:{} };
            return state;
        case "/profile/SET_PERSONAL_PROFILE_BUSINESS_PAGES_SUCCESS":
            state = {
                ...state,
                personalBusinessPages: action.payload
            };
            return state;
        case "/profile/SET_PROFILE_COMPLETION_REMINDER":
            state = { ...state, profileCompletionReminderData:{} };
            return state;
        case "/profile/SET_PROFILE_COMPLETION_REMINDER_SUCCESS":
            state = {
                ...state,
                profileCompletionReminderData: action.payload
            };
            return state;
        case "/profile/RESET_PROFILE_COMPLETION_REMINDER":
            state = {
                ...state,
                profileCompletionReminderData: initialState.profileCompletionReminderData
            };
            return state;
        default:
            return state;
    }
    return state;
};

export default ProfileReducer;

export function setProfileData(response) {
    return { type: SET_PERSONAL_PROFILE_DATA,payload:response };
}

export function setProfileDataOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_DATA_SUCCESS, payload: state };
}
export function setProfileContactInfo(response) {
    return { type: SET_PERSONAL_CONTACT_INFO ,payload:response};
}

export function setProfileContactInfoOnSuccess(state) {
    return { type: SET_PERSONAL_CONTACT_INFO_SUCCESS, payload: state };
}
export function setPersonalProfileAddressData(response) {
    return { type: SET_PERSONAL_PROFILE_ADDRESS,payload:response };
}

export function setPersonalProfileAddressDataOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_ADDRESS_SUCCESS, payload: state };
}
export function setPersonalProfileBio(response) {
    return { type: SET_PERSONAL_PROFILE_BIO,payload:response };
}

export function setPersonalProfileBioDataOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_BIO_SUCCESS, payload: state };
}
export function setPersonalProfileSkillsData(response) {
    return { type: SET_PERSONAL_PROFILE_SKILLS,payload:response };
}

export function setPersonalProfileSkillsDataOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_SKILLS_SUCCESS, payload: state };
}

export function setPersonalProfileSkillsAndSpecializationData(response) {
    return { type: SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS,payload:response };
}

export function setPersonalProfileSkillsAndSpecializationDataOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS_SUCCESS, payload: state };
}
export function setPersonalProfileInterestData(response) {
    return { type: SET_PERSONAL_PROFILE_INTERESTS,payload:response };
}

export function setPersonalProfileInterestsDataOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_INTERESTS_SUCCESS, payload: state };
}
export function setPersonalProfileSpecialitiesData(response) {
    return { type: SET_PERSONAL_PROFILE_SPECIALITIES,payload:response };
}

export function setPersonalProfileSpecialitiesDataOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_SPECIALITIES_SUCCESS, payload: state };
}

export function setPersonalProfileEducations(response) {
    return { type: SET_PERSONAL_PROFILE_EDUCATION,payload:response };
}

export function setPersonalProfileEducationsOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_EDUCATION_SUCCESS, payload: state };
}

export function setPersonalProfileExperience(response) {
    return { type: SET_PERSONAL_PROFILE_EXPERIENCE,payload:response };
}

export function setPersonalProfileExperienceOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_EXPERIENCE_SUCCESS, payload: state };
}
export function setPersonalProfileHashTags(response) {
    return { type: SET_PERSONAL_PROFILE_POPULAR_HASH_TAGS,payload:response };
}

export function setPersonalProfileHashTagsOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_POPULAR_HASH_TAGS_SUCCESS, payload: state };
}
export function setPersonalProfileNotJoinedCauses(response) {
    return { type: SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE,payload:response };
}

export function setPersonalProfileNotJoinedCausesOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE_SUCCESS, payload: state };
}
export function setPersonalProfileJoinedCauses(response) {
    return { type: SET_PERSONAL_PROFILE_JOINED_CAUSE,payload:response };
}

export function setPersonalProfileJoinedCausesOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_JOINED_CAUSE_SUCCESS, payload: state };
}
export function setPersonalProfileRecentActivity(response) {
    return { type: SET_PERSONAL_PROFILE_RECENT_ACTIVITY,payload:response };
}

export function setPersonalProfileRecentActivityOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_RECENT_ACTIVITY_SUCCESS, payload: state };
}

export function setPersonalProfileBusinessPages(response) {
    return { type: SET_PERSONAL_PROFILE_BUSINESS_PAGES,payload:response};
}

export function setPersonalProfileBusinessPagesOnSuccess(state) {
    return { type: SET_PERSONAL_PROFILE_BUSINESS_PAGES_SUCCESS, payload: state };
}

export function setProfileBasicInfoDetails(response) {
    return { type: SET_PROFILE_BASIC_INFO_DETAILS, payload:response};
}

export function setProfileBasicInfoDetailsOnSuccess(state) {
    return { type: SET_PROFILE_BASIC_INFO_DETAILS_SUCCESS, payload: state };
}

export function setProfileCompletionReminderDetails(response) {
    return { type: SET_PROFILE_COMPLETION_REMINDER, payload:response};
}

export function setProfileCompletionReminderDetailsOnSuccess(state) {
    return { type: SET_PROFILE_COMPLETION_REMINDER_SUCCESS, payload: state };
}

export function resetProfileCompletionReminderDetails() {
    return {type: RESET_PROFILE_COMPLETION_REMINDER};
}
