import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "../api/PersonalProfileAPI";

import {
    setProfileDataOnSuccess,
    setPersonalProfileAddressDataOnSuccess,
    setPersonalProfileBioDataOnSuccess,
    setPersonalProfileSkillsDataOnSuccess,
    setPersonalProfileInterestsDataOnSuccess,
    setPersonalProfileSpecialitiesDataOnSuccess,
    setPersonalProfileEducationsOnSuccess,
    setPersonalProfileExperienceOnSuccess,
    setPersonalProfileHashTagsOnSuccess,
    setPersonalProfileNotJoinedCausesOnSuccess,
    setPersonalProfileJoinedCausesOnSuccess,
    setPersonalProfileRecentActivityOnSuccess,
    setPersonalProfileBusinessPagesOnSuccess,
    setProfileContactInfoOnSuccess,
    setProfileBasicInfoDetailsOnSuccess,
    setProfileCompletionReminderDetailsOnSuccess,
    SET_PERSONAL_PROFILE_DATA,
    SET_PERSONAL_PROFILE_ADDRESS,
    SET_PERSONAL_PROFILE_BIO,
    SET_PERSONAL_PROFILE_SKILLS,
    SET_PERSONAL_PROFILE_INTERESTS,
    SET_PERSONAL_PROFILE_SPECIALITIES,
    SET_PERSONAL_PROFILE_EDUCATION,
    SET_PERSONAL_PROFILE_EXPERIENCE,
    SET_PERSONAL_PROFILE_POPULAR_HASH_TAGS,
    SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE,
    SET_PERSONAL_PROFILE_JOINED_CAUSE,
    SET_PERSONAL_PROFILE_RECENT_ACTIVITY,
    SET_PERSONAL_PROFILE_BUSINESS_PAGES,
    SET_PERSONAL_CONTACT_INFO,
    setPersonalProfileSkillsAndSpecializationDataOnSuccess,
    SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS,
    SET_PROFILE_BASIC_INFO_DETAILS,
    SET_PROFILE_COMPLETION_REMINDER
} from "../../reducer/profile/ProfileReducer";

function* loadProfileData(action) {
    try {
        let response = yield call(api.fetchProfileData,action.payload);
        yield put(setProfileDataOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadProfileBasicInfoData(action) {
    try {
        let response = yield call(api.fetchProfileBasicInfoData,action.payload);
        yield put(setProfileBasicInfoDetailsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadContactInfo(action) {
    try {
        let response = yield call(api.fetchContactInfo,action.payload);
        yield put(setProfileContactInfoOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileAddressData(action) {
    try {
        let response = yield call(api.fetchPersonalProfileAddressData,action.payload);
        yield put(setPersonalProfileAddressDataOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileBioData(action) {
    try {
        let response = yield call(api.fetchPersonalProfileBioData,action.payload);
        yield put(setPersonalProfileBioDataOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileSkillsData(action) {
    try {
        let response = yield call(api.fetchPersonalProfileSkillsData,action.payload);
        yield put(setPersonalProfileSkillsDataOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileSkillsAndSpecializationData(action) {
    try {
        let response = yield call(api.fetchPersonalProfileSkillsAndSpecializationData,action.payload);
        yield put(setPersonalProfileSkillsAndSpecializationDataOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export  function* watchPersonalProfileSkillsData() {
    yield takeEvery(SET_PERSONAL_PROFILE_SKILLS, loadPersonalProfileSkillsData);
}

export  function* watchPersonalProfileSkillsAndSpecializationData() {
    yield takeEvery(SET_PERSONAL_PROFILE_SKILLS_SPECIALIZATIONS, loadPersonalProfileSkillsAndSpecializationData);
}

function* loadPersonalProfileSpecialitiesData(action) {
    try {
        let response = yield call(api.fetchPersonalProfileSpecialtiesData,action.payload);
        yield put(setPersonalProfileSpecialitiesDataOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileEducations(action) {
    try {
        let response = yield call(api.fetchPersonalProfileEducation,action.payload);
        yield put(setPersonalProfileEducationsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileExperience(action) {
    try {
        let response = yield call(api.fetchPersonalProfileExperience,action.payload);
        yield put(setPersonalProfileExperienceOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}
function* loadPersonalProfilePopularHashTags(action) {
    try {
        let response = yield call(api.fetchPersonalProfileHashTags,action.payload);
        yield put(setPersonalProfileHashTagsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileNotJoinedCauses(action) {
    try {
        let response = yield call(api.fetchPersonalProfileNotJoinedCauses,action.payload);
        yield put(setPersonalProfileNotJoinedCausesOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}
function* loadPersonalProfileJoinedCauses(action) {
    try {
        let response = yield call(api.fetchPersonalProfileJoinedCauses,action.payload);
        yield put(setPersonalProfileJoinedCausesOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileRecentActivity(action) {
    try {
        let response = yield call(api.fetchPersonalProfileRecentActivity,action.payload);
        yield put(setPersonalProfileRecentActivityOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadPersonalProfileBusinesspages(action) {
    try {
        let response = yield call(api.fetchPersonalProfileBusinessPages,action.payload);
        yield put(setPersonalProfileBusinessPagesOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

function* loadProfileCompletionReminderDetails(action) {
    try {
        let response = yield call(api.fetchProfileCompletionReminderData,action.payload);
        yield put(setProfileCompletionReminderDetailsOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export  function* watchPersonalProfileSpecialitiesData() {
    yield takeEvery(SET_PERSONAL_PROFILE_SPECIALITIES, loadPersonalProfileSpecialitiesData);
}

export  function* watchPersonalProfileEducations() {
    yield takeEvery(SET_PERSONAL_PROFILE_EDUCATION, loadPersonalProfileEducations);
}

export  function* watchPersonalProfileExperience() {
    yield takeEvery(SET_PERSONAL_PROFILE_EXPERIENCE, loadPersonalProfileExperience);
}

export  function* watchPersonalProfilePopularHashTags() {
    yield takeEvery(SET_PERSONAL_PROFILE_POPULAR_HASH_TAGS, loadPersonalProfilePopularHashTags);
}
export  function* watchPersonalProfileNotJoinedCauses() {
    yield takeEvery(SET_PERSONAL_PROFILE_NOT_JOINED_CAUSE, loadPersonalProfileNotJoinedCauses);
}
export  function* watchPersonalProfileJoinedCauses() {
    yield takeEvery(SET_PERSONAL_PROFILE_JOINED_CAUSE, loadPersonalProfileJoinedCauses);
}

export  function* watchPersonalProfileRecentActivity() {
    yield takeEvery(SET_PERSONAL_PROFILE_RECENT_ACTIVITY, loadPersonalProfileRecentActivity);
}

export  function* watchPersonalProfileBusinessPages() {
    yield takeEvery(SET_PERSONAL_PROFILE_BUSINESS_PAGES, loadPersonalProfileBusinesspages);
}

function* loadPersonalProfileInterestsData(action) {
    try {
        let response = yield call(api.fetchPersonalProfileInterestsData,action.payload);
        yield put(setPersonalProfileInterestsDataOnSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export  function* watchPersonalProfileInterestsData() {
    yield takeEvery(SET_PERSONAL_PROFILE_INTERESTS, loadPersonalProfileInterestsData);
}

export  function* watchPersonalProfileBioData() {
    yield takeEvery(SET_PERSONAL_PROFILE_BIO, loadPersonalProfileBioData);
}

export  function* watchPersonalProfileAddressData() {
    yield takeEvery(SET_PERSONAL_PROFILE_ADDRESS, loadPersonalProfileAddressData);
}

export  function* watchContactInfo() {
    yield takeEvery(SET_PERSONAL_CONTACT_INFO, loadContactInfo);
}

export default function* watchProfileData() {
    yield takeEvery(SET_PERSONAL_PROFILE_DATA, loadProfileData);
}

export function* watchProfileBaicInfoData() {
    yield takeEvery(SET_PROFILE_BASIC_INFO_DETAILS, loadProfileBasicInfoData);
}

export function* watchProfileCompletionReminderData() {
    yield takeEvery(SET_PROFILE_COMPLETION_REMINDER, loadProfileCompletionReminderDetails);
}




